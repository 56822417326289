<template>
  <div class="main clearfix">
    <!-- <div class="m_nav">
      <div class="m_nav_box">
          
      </div>
    </div> -->
    <CurrentLocation />
    <div class="box">
      <div class="ct2-sd">
      <PanelNav :title="title" :navs="navs" />
      </div>
    <div class="ct3-mn">
      <PanelList :more="true" :data="data" />
    </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue";
import CurrentLocation from "../layout/components/CurrentLocation";
import PanelNav from "../layout/components/PanelNav";
import PanelLast from "../layout/components/PanelLast";
import PanelList from "../layout/components/PanelList";

import axios from "@/api/apis";

export default {
  name: "Tech",
  components: { CurrentLocation, PanelNav, PanelLast, PanelList },
  created() {},
  setup() {
    const state = reactive({
      title: "技术前瞻",
      data: {
        currentPage: "",
        size: "",
        total: "",
        list: [],
      },
    });
    const loadArticle = () => {
      axios
        // .post("/article/page", { group: 3, pageSize: 5, current: 1 })
        .post("https://cqgdrm.com/api/website/article/page", { group: 5, pageSize: 100, current: 1 })
        .then(function (data) {
          state.data.currentPage = data.currentPage;
          state.data.size = data.size;
          state.data.total = data.total;

          data.data.forEach((item) => {
            var image=''
            if(item.images!= null){
            image=item.images[0]
          }else{
            image=''
          }
            state.data.list.push({
              id: item.id,
              title: item.title,
              type: item.type,
              abstracts:item.abstracts,
              content: item.content,
              images:image,
              publishTime: item.publishTime,
              router: "/party/details",
            });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    onMounted(() => {
      loadArticle();
    });
    return {
      ...toRefs(state),
    };
  },
};
</script>

<style scoped>
 .ct3-mn {
    /* margin-left: 200px; */
    display: block;
    float: right;
    width: 910px;
  }
</style>
